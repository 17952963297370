import React from "react";

function Protection() {
  return (
    <>
      <h1>All-Around Complex Protection</h1>
      <p>
        It may be shocking to hear, but the leading lights of the storage tank
        fire-protection industry often oppose major technology improvements.
      </p>
      <p>
        This may sound like a paradox. Fire safety is one of the most
        technology-driven industries, and yet the majority of market players
        have been offering the same mechanics for 30 years. Unfortunately, if
        you think about it… it starts to make sense. Precisely because the
        fire-safety industry is so technology driven, every vendor must always
        be perceived as top notch. If a completely new, more reliable,
        affordable and effective technology comes around, it disturbs the
        established supply chain.
      </p>
      <p>
        Either everyone must switch to the new technology – tacitly admitting
        that it’s better than what they’ve been selling so far – or try to
        discredit it. If you look closely, you see that system designers have
        been making only incremental improvements and updates for decades. And
        these systems have repeatedly failed to handle large-scale incidents.
      </p>
      <p>
        Having specialized emergency units on hand greatly increases your
        chances of controlling or even extinguishing a full-surface fire. Since
        these teams have greater capacity, they can effectively isolate the fire
        if it hasn’t spread by the time they arrive. The costs and losses for
        your company are still very high, albeit lower than they would be in
        other scenarios. A system that qualifies as “emergency-level” must
        quickly extinguish even the largest full-surface fires without external
        intervention. This depends on two factors: The method of applying the
        foam and the attainable foam-application rate.
      </p>
      <p>
        In order to quickly attain the critical foam-blanket thickness on the
        surface of the burning liquid, the foam must be applied much faster than
        it burns away. This means the foam must be introduced directly onto the
        burning liquid surface from all sides. The larger the size of the tank,
        the larger the foam-application capacity must be.
      </p>
      <p>
        When fire strikes, time is of the essence. To put things into
        perspective, our system can even extinguish a 120m/394ft diameter
        storage-tank fire easily, without a water network or electricity, in
        less than three minutes. The Pi Foam System is the innovative
        fire-extinguishing system of the 21st century. Your storage tanks,
        terminals or oil rigs will stay safe all day, every day.
      </p>
    </>
  );
}

export default Protection;
