import React from "react";

function AnySituation() {
  return (
    <>
      <h1>Handling Any Situation</h1>
      <p>
        Extreme weather, technical malfunctions, sabotage or a chain of mishaps
        can all touch off a full-surface fire. Your extinguishment capacities
        determine whether you can save your burning tank and prevent the fire
        from escalating, wich may threaten your entire facility. The question
        is, have you got what it takes to extinguish a fire?
      </p>
      <p>
        If a fire ignites one of your tanks… your options narrow down. You must
        regain control of the situation using the best you’ve got. You rely on
        prevention technologies — lightning-protection devices, advanced roof
        structures – to avoid such incidents. A low-level fire-intervention
        protocol may prevent the incident from escalating into a devastating
        crisis. Your options may include mobile fire brigades, low-capacity
        rim-seal fire protection systems, etc.… but what if they all fail?
      </p>
      <p>
        If a fire ignites one of your tanks… your options narrow down. You must
        regain control of the situation using the best you’ve got. You rely on
        prevention technologies — lightning-protection devices, advanced roof
        structures – to avoid such incidents. A low-level fire-intervention
        protocol may prevent the incident from escalating into a devastating
        crisis. Your options may include mobile fire brigades, low-capacity
        rim-seal fire protection systems, etc.… but what if they all fail?
      </p>
      <p>
        A conventional fixed system with the minimum recommended
        foam-application rate is able to extinguish rim-seal fires. A fixed,
        automated rim-seal fire-protection system is the bare minimum every site
        must have. If a full surface fire were to occur in a tank larger than
        40m/131ft in diameter, basic protection systems won’t make much
        difference – such fires are just too powerful. If the basic systems
        can’t muster the combined extinguishment capacity to overpower the fire,
        the firefighters’ only option is to allow a controlled burn-down while
        cooling the surrounding tanks to prevent the fire from spreading.
      </p>
      <p>
        For example, under NFPA 11 standards, traditional fixed
        fire-extinguishing systems must maintain a foam intensity of around 4
        L/m2/min (0.1 gpm/sqft) for 30 minutes, which means 120 L/m2 (2.95
        gal/sqft) altogether. The Pi Foam System can introduce the same amount
        of foam to the tank in three minutes (40L/10.6gal for three minutes).
        That means the foam does not break down and smothers the fire before it
        can inflict significant damage on the tank.
      </p>
      <p>
        Mobile emergency units (e.g. Williams Fire and Hazard Control) have the
        equipment, experience, and strategies that give them a fighting chance
        against large full-surface fires. Specialized emergency units are able
        to scale up their foam-application capacity with powerful monitors,
        pumps, and proportioners. If circumstances are ideal – the fire doesn’t
        spread, there is sufficient water, conditions don’t worsen – they can
        extinguish a fire within a few hours, or at least help control the
        situation and prevent it from escalating.
      </p>
      <p>
        But there are three important factors to consider:
        <ul style={{ listStyle: "initial" }}>
          <li>The time it takes for firefighters to arrive on the scene</li>
          <li>The resources available to them</li>
          <li>
            The damage the fire will inflict on the tank and the site while
            waiting for firefighters to arrive and prepare.
          </li>
        </ul>
      </p>
      <p>
        The Pressurized Instant Foam Fire-Protection System is a fully automatic
        tank-fire fighting technology that can put out fires in three minutes,
        irrespective of the tank size and weather conditions. It makes no
        difference whether the tank is in a desert or the temperature is minus
        40°C/°F. There is no need for water networks, electricity or specialist
        firefighting crews.
      </p>
    </>
  );
}

export default AnySituation;
