import React from "react";
import { withPrefix } from "gatsby";
import pdficon from "../../images/icons/pdf.svg";
import totalCosts from "../../images/total-costs.png";

function Costs() {
  return (
    <>
      <h1>Costs</h1>
      <p>
        We have prepared a thorough comparative analysis of the costs of
        different fire-protection systems for combustible-liquid storage tanks.
        We divided the various technologies into four categories: Mobile
        systems, semi-stable systems, fixed systems, and the Pi Foam System.
      </p>
      <p>
        Naturally, the Pi Foam System is capable of protecting not only tanks,
        but also the dike and other technological areas. However, we omitted
        these areas from our study because they would have made the calculations
        inordinately complex and including them would not have affected the
        final result. We focus exclusively on storage tanks. We conducted a
        comparative cost analysis for each of the four systems at a medium-sized
        tank (50m/164ft-diameter) in a medium-sized storage facility (20 tanks).
        Calculations were conducted in line with industry standards published by
        the National Fire Protection Association (NFPA) and European Standards
        (EN).
      </p>
      <p>
        Our study calculates how many years it would take for each system to
        provide a return on investment. At first glance, an extinguishment
        system does not generate profit, so it may be difficult to understand
        the concept of “breaking even.” However, we can calculate a “break-even”
        cost by evaluating the dollar amount of damage that would be inflicted
        by a full-surface fire at a single storage container at the tank farm
        described above. We then multiply this by the annual statistical
        probability of such a fire occurring. This theoretical cost of a fire
        event became our “alternative cost,” or the cost of “risking it” by
        failing to install a fire-extinguishment system. We then compared this
        cost to the annual OPEX for a single tank equipped with one of the
        fire-extinguishment systems in our survey. If the annual OPEX is greater
        than the “alternative cost,” then the system will never break even.
        However, if there is a “profit” – that is, if the system’s annual OPEX
        for a single tank is less than the cost of “risking it” for a year –
        then we calculated how many years it would take for the investor to
        break even on the CAPEX for installing the system.
      </p>
      <p>
        Among the three traditional technologies,{" "}
        <strong>mobile systems</strong> have the lowest upfront investment cost.
        However, the cost of salaries for an on-site team of firefighters, on
        duty 24 hours a day, makes this the most expensive option in the long
        term. Over a 20-year period, maintenance and operational expenses and
        the <strong>total expenses will be the highest</strong>. And since the
        OPEX is higher than the alternative cost of risk, the investor will{" "}
        <strong>never break even</strong>.
      </p>
      <p>
        <strong>
          Now, the benchmark will be the mobile system cost, as 100%.
        </strong>
      </p>
      <p>
        Installing a <strong>semi-stable system</strong> is somewhat more
        expensive, but the operating costs are almost as high as those of a
        mobile system.{" "}
        <strong>
          Over 20 years, total costs will be 20% less than with mobile system
        </strong>
        . Thanks to the high OPEX, the investor will never get a return.
      </p>
      <p>
        A <strong>fixed system</strong> incurs the highest installation costs.
        It also operates automatically, meaning it can be maintained with
        minimal human resources. However, the complex machinery requires a
        considerable amount of maintenance.{" "}
        <strong>
          Over 20 years, total costs will be 70% less than with mobile system
        </strong>
        . Here, we can finally discuss a return on investment, because the OPEX
        is lower than the alternative cost of risk. An investor would break even
        in approximately 15 years.
      </p>
      <p>
        The <strong>Pi Foam system</strong> qualifies as a fixed-automatic
        system, without the complicated machinery of a traditional fixed system.
        This means savings on the OPEX side as well as significantly greater
        operational reliability.
      </p>
      <p>
        Maintenance is incredibly simple. The system monitors its own pressure
        and sends a signal if it detects a problem. Otherwise, the only
        maintenance required is regular functionality tests on the valve and an
        annual foam sample to check for proper consistency. These costs come to
        less than a few thousand USD a year.{" "}
        <strong>
          Over 20 years, total costs will be 80% less than with mobile system.
        </strong>
      </p>
      <p>
        Since this system offers the lowest installation and maintenance costs
        of all the systems in our study, the time required for an investor to{" "}
        <strong>break even</strong> is also very advantageous –{" "}
        <strong>just seven (7) years</strong>.
      </p>
      <p>
        We believe the most important lesson is that it is always worthwhile to
        thoroughly evaluate a firefighting system from a professional and
        financial standpoint, even if it appears to be the most expensive at
        first glance. This is especially true when discussing a technology as
        efficient and low maintenance as the Pi Foam System. For those who would
        argue that a fixed-extinguishment system cannot respond to all types of
        fire events (a problem that we believe can be resolved through proper
        design), we suggest that the best price-value option would be the Pi
        Foam System’s instant-reaction capabilities (no preparation time
        required) combined with keeping a smaller-capacity mobile fire brigade
        at the ready.
      </p>
      <p>
        If you are interested in additional details, we invite you to download
        the case study below.
      </p>
      <img src={totalCosts} style={{ width: "100%" }} alt="total costs" />
      <a
        className="mission--center"
        href={withPrefix("/PI_Foam_financial_study.pdf")}
        target="blank"
      >
        <img
          style={{ width: 50, height: 50 }}
          src={pdficon}
          alt="pdf download icon"
        />
        <p>
          <strong>Downloadable Financial Study</strong>
        </p>
      </a>
    </>
  );
}

export default Costs;
