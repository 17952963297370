import React from "react";

function NotOnlyTankFires() {
  return (
    <>
      <h1>Not Only Tank Fires</h1>
      <p>
        We mainly advertise the Pi Foam System as a storage-tank firefighting
        technology, but it is naturally capable of protecting dikes, pump
        stations, loading stations for trucks, trains, and ships, and other
        areas. It offers all-around complex protection for any site where foam
        is used to extinguish fires.
      </p>
      <p>The Pi Foam System is unlike any other on the market!</p>
      <p>
        It can protect against sabotage. The automatic detection system reacts
        quickly to any scenario, without depending on externalities such as
        manpower, electricity or water networks.
      </p>
      <p>
        The Pi Foam System was developed by engineering geniuses who designed it
        to be 100% compatible with current firefighting systems.
      </p>
      <p>
        Once installed, the Pi Foam System can be the “central brain” of your
        tank farm, distributing foam to all your installations via underground
        pipelines.
      </p>
      <p>
        The Pi Foam System is fully capable of accommodating all current
        foam-dispensing devices, creating a complete next-generation system that
        supplies all equipment with the required quantity of foam.
      </p>
      <p>
        This equipment includes foam pourers, foam monitors, hand monitors, foam
        sprinklers and bare open pipe ends – even devices such as the Circular
        Injection Ring, which traditional firefighting systems can’t accommodate
        due to its performance limitations.
      </p>
      <p>
        The Pi Foam System operates independently as one giant network – and all
        this for a fraction of the lifetime price of traditional systems.
      </p>
    </>
  );
}

export default NotOnlyTankFires;
