import React from "react";
import storefire from "../../images/mission/storefire.jpg";

function PiFoam() {
  return (
    <>
      <h1>Introducing the Pressurized Instant (Pi) Foam System</h1>
      <p>
        The Pressurized Instant (Pi) Foam System offers an unprecedented,
        compromise-free solution to storage-tank disasters. It sets a new
        standard in the oil, chemical, pharmaceutical and vegetable-oil
        industries. We offer a variety of foam compounds, each of which is
        tailored to the specific fire hazards that threaten each individual
        industry.
      </p>
      <img src={storefire} alt="extinguishing tank fire with pi foam system" />
      <p>
        The Pi Foam System can also protect storage tanks from sabotage or
        terrorism with a special option that addresses these extreme risks.
      </p>
      <p>
        The Pi Foam System represents the third and fourth generations of Swiss
        Fire Protection AG’s original firefighting system, known as “Foam
        Fatale.”
      </p>
    </>
  );
}

export default PiFoam;
