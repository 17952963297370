import React from "react";

function OurMission() {
  return (
    <>
      <h1>Our Mission</h1>
      <p>
        Swiss Fire Protection R&D AG, the developer of the Pi Foam System, wants
        to change the oil and chemical industry by raising awareness about the
        new potential for saving lives and property – our #1 priority.
      </p>
      <p>
        We aim to revolutionize the way firefighters battle combustible-liquid
        fires. First, we do it fast: We have reduced the duration of
        storage-tank fires from days to just π minutes. There is no tank damage
        and virtually no air pollution. Second, we care for the environment: The
        Pi system introduces the foam directly into the tank, avoiding any
        updraft loss and instantly cooling the tank walls. There is no foam
        spillage and no soil contamination, which usually poses a much graver
        threat to the environment than air pollution during firefighting
        operations.
      </p>
      <p>
        We offer a non-toxic, biodegradable foam that is infinitely more
        environment-friendly than the traditional foams currently used by other
        systems. We can also equip the system with standard commercial foams if
        the client so desires.
      </p>
      <p>
        When fire strikes, time is of the essence. The Pi Foam System
        extinguishes fire and eliminates danger instantly. The system operates
        automatically and does not put firefighters’ lives at risk.
      </p>
      <p>
        Uncompromised performance was key from Day One. We have managed to
        accomplish just that.
      </p>
    </>
  );
}

export default OurMission;
