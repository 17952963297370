import React, { useRef, useState } from "react";
import Layout from "../components/Layout";
import { intoView as scroll } from "zenscroll";
// Article components
import OurMission from "../components/mission/OurMission";
import SavingLives from "../components/mission/SavingLives";
import PiFoam from "../components/mission/PiFoam";
import AnySituation from "../components/mission/AnySituation";
import Protection from "../components/mission/Protection";
import Standards from "../components/mission/Standards";
import NotOnlyTankFires from "../components/mission/NotOnlyTankFires";
import BioFoams from "../components/mission/BioFoams";
import Costs from "../components/mission/Costs";
// Styles
import "../styles/global.css";
import "../styles/mission.css";
import "../styles/pageHeader.css";

const content = [
  OurMission,
  SavingLives,
  PiFoam,
  AnySituation,
  Protection,
  Standards,
  NotOnlyTankFires,
  BioFoams,
  Costs,
];

const menu = [
  "Our Mission",
  "Saving Lives",
  "Introducing the PI Foam System",
  "Handling Any Situation",
  "All-Around Complex Protection",
  "Standards",
  "Not Only Tank Fires",
  "Biodegradable Foams",
  "Costs",
];

function Mission() {
  const [current, setCurrent] = useState(content[0]);
  const [index, setIndex] = useState(0);
  const ref = useRef(null);

  return (
    <Layout>
      <main className="mission__container page__container">
        <h1 className="page__title">Our Mission</h1>
        <section className="mission__wrapper">
          <div>
            <div className="mission__menu">
              {menu.map((m, i) => (
                <div
                  className={i === index ? "m__active" : ""}
                  onClick={() => {
                    setCurrent(content[i]);
                    setIndex(i);
                    if (window.innerWidth < 1000) scroll(ref.current);
                  }}
                  key={i}
                >
                  {m}
                </div>
              ))}
            </div>
          </div>
          <article ref={ref} className="mission__article">
            {current}
          </article>
        </section>
      </main>
    </Layout>
  );
}

export default Mission;
