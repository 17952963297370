import React from "react";
import lastfire from "../../images/mission/lastfire.jpg";

function Standards() {
  return (
    <>
      <h1>Standards</h1>
      <p>
        There is usually a certain delay before a new technology appears in the
        relevant industry standards. In most cases, the standards only acclimate
        to innovations, along with their technical requirements, once industry
        players are already using them on a widespread basis. For this reason,
        the development of a good system is the shared responsibility of the
        engineer (who designs the system) and the manager (who chooses the
        engineer), independent of the groups that publish standards. In our
        view, the standards provide adequate guidance for creating an average
        system, but a truly state-of-the-art design requires extensive
        experience and comprehensive knowledge of the latest technologies.
      </p>
      <p>
        When designing any Pi Foam system we combine this state-of-the-art
        know-how with the already proven knowledge of the industry, we always
        incorporate the relevant ISO, NFPA, EN, ASME, ASTM, API, BPVC, PED codes
        and standards.
      </p>
      <p>
        Tested by Lastfire using the Lastfire Protocol to assess NFPA 11
        equivalency (full report available by request from Lastfire or SFPRD).
        Under review by NFPA 11.
      </p>
      <p>
        Tested by Tüv Süd for EN 13565-2 equivalency (full report available by
        request).
      </p>
      <div className="mission--center">
        <img
          src={lastfire}
          style={{ maxWidth: "100%", marginBottom: 10 }}
          alt="lastfire logo"
        />
      </div>
      <iframe
        style={{ marginBottom: 20 }}
        src="https://player.vimeo.com/video/325898953"
        width="100%"
        height="360"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen=""
        id="player_7"
        data-ready="true"
      ></iframe>
    </>
  );
}

export default Standards;
