import React from "react";

function BioFoams() {
  return (
    <>
      <h1>Biodegradable Foams</h1>
      <p>
        Environmental protection is a priority for us. We have therefore
        developed biodegradable foams that are certified as non-PBT
        (non-persistent, non-bioaccumulative, non-toxic), do not contain
        fluorine and are made of natural substances. The Pi Foam System can also
        be used with traditional foams. For details, see the “Biodegradable
        Foams” section below.
      </p>
    </>
  );
}

export default BioFoams;
