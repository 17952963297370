import React from "react";

function SavingLives() {
  return (
    <>
      <h1>Are you sure you can keep your people and your facility safe?</h1>
      <p>
        The safety and health of employees is the number one priority for every
        company. The Pi Foam System is the safest and the most effective
        fire-extinguishing system on the market. Our revolutionary technology
        will usher in a new era of safety and quality in the oil and chemical
        industries – an era where the consequences of large-scale accidents are
        marginal.
      </p>
      <p>
        In our opinion, a safety professional at a storage terminal should be
        like a ghost. Mind everything, be everywhere – but remain unseen. The
        workers and nearby residents need to know that they are safe, no matter
        what. Storage tanks are high-risk facilities. They contain flammable
        petrochemicals in large quantities. The company’s duty is to keep
        everything and everyone safe. Are you able to control any fire at your
        storage-tank site, no matter what the circumstances?
      </p>
      <p>
        Research shows that everyone thinks that they have the right tools to
        extinguish any storage-tank fire. Well, think again! In most cases,
        local fire units are the first to respond to industrial-fire incidents.
        Small fire brigades generally lack the resources and training to handle
        fires of such magnitude as a full-surface storage-tank fire. Their best
        option is to secure the area and focus their resources on cooling
        adjacent tanks to prevent the fire from escalating. A controlled
        transloading of oil in the tank is also a good strategy, when
        applicable.
      </p>
      <p>
        It may be frightening to realize, but most tanks that store flammable
        liquids lack even minimal fire protection, according to informed
        sources. If a full-surface fire breaks out, it can easily endanger the
        entire site and everyone living nearby. The scale of the disaster rises
        exponentially if a fire spreads from one tank to others.
      </p>
      <p>
        The Pi Foam System reacts instantly, putting out fires before they cause
        catastrophe.
      </p>
    </>
  );
}

export default SavingLives;
